import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { InternUserService } from './intern-user.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
  private headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  private options = { headers: this.headers };

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: InternUserService,
    private cookieService: CookieService,
  ) {}

  isAuthenticated(): Promise<boolean> {
    if (this.cookieService.check('session-ent')) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated().then(result => {
      if (!result) {
        this.router.navigate(['/auth']);
      }

      return this.userService.initUser();
    });
  }
}
