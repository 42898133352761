export * from './auth.service';
import { AuthService } from './auth.service';
export * from './credential.service';
import { CredentialService } from './credential.service';
export * from './image.service';
import { ImageService } from './image.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './right.service';
import { RightService } from './right.service';
export * from './school.service';
import { SchoolService } from './school.service';
export * from './service.service';
import { ServiceService } from './service.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './userToRight.service';
import { UserToRightService } from './userToRight.service';
export const APIS = [AuthService, CredentialService, ImageService, NewsService, RightService, SchoolService, ServiceService, UserService, UserToRightService];
