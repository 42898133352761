/**
 * ENT API
 *  A RESTful API for ENT app
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ServiceView } from '../model/serviceView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ServiceService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create Service
     *
     * @param title
     * @param description
     * @param file
     * @param serviceurl
     * @param rights
     * @param schools
     * @param active
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling createServiceActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling createServiceActionIndex.');
        }

        if (serviceurl === null || serviceurl === undefined) {
            throw new Error('Required parameter serviceurl was null or undefined when calling createServiceActionIndex.');
        }

        if (rights === null || rights === undefined) {
            throw new Error('Required parameter rights was null or undefined when calling createServiceActionIndex.');
        }

        if (schools === null || schools === undefined) {
            throw new Error('Required parameter schools was null or undefined when calling createServiceActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling createServiceActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (serviceurl !== undefined) {
            formParams = formParams.append('serviceurl', <any>serviceurl) as any || formParams;
        }
        if (rights) {
            rights.forEach((element) => {
                formParams = formParams.append('rights', <any>element) as any || formParams;
            })
        }
        if (schools) {
            schools.forEach((element) => {
                formParams = formParams.append('schools', <any>element) as any || formParams;
            })
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/Services`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Service
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteServiceActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteServiceActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteServiceActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteServiceActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteServiceActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/v1/Services/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Service
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServiceActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<ServiceView>;
    public getServiceActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceView>>;
    public getServiceActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceView>>;
    public getServiceActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getServiceActionIndex.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ServiceView>('get',`${this.basePath}/api/v1/Services/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Services
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServiceActionList(observe?: 'body', reportProgress?: boolean): Observable<Array<ServiceView>>;
    public getServiceActionList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ServiceView>>>;
    public getServiceActionList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ServiceView>>>;
    public getServiceActionList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ServiceView>>('get',`${this.basePath}/api/v1/Services`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all active Services
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServiceActionListActive(observe?: 'body', reportProgress?: boolean): Observable<Array<ServiceView>>;
    public getServiceActionListActive(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ServiceView>>>;
    public getServiceActionListActive(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ServiceView>>>;
    public getServiceActionListActive(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ServiceView>>('get',`${this.basePath}/api/v1/Services/active/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Services by right id
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServiceActionListBySchool(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ServiceView>>;
    public getServiceActionListBySchool(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ServiceView>>>;
    public getServiceActionListBySchool(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ServiceView>>>;
    public getServiceActionListBySchool(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getServiceActionListBySchool.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ServiceView>>('get',`${this.basePath}/api/v1/Services/right/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Service
     *
     * @param title
     * @param description
     * @param file
     * @param serviceurl
     * @param rights
     * @param schools
     * @param active
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateServiceActionIndexForm(title: string, description: string, file: Blob, serviceurl: string, rights: Array<string>, schools: Array<string>, active: boolean, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling updateServiceActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling updateServiceActionIndex.');
        }

        if (serviceurl === null || serviceurl === undefined) {
            throw new Error('Required parameter serviceurl was null or undefined when calling updateServiceActionIndex.');
        }

        if (rights === null || rights === undefined) {
            throw new Error('Required parameter rights was null or undefined when calling updateServiceActionIndex.');
        }

        if (schools === null || schools === undefined) {
            throw new Error('Required parameter schools was null or undefined when calling updateServiceActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling updateServiceActionIndex.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateServiceActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (serviceurl !== undefined) {
            formParams = formParams.append('serviceurl', <any>serviceurl) as any || formParams;
        }
        if (rights) {
            rights.forEach((element) => {
                formParams = formParams.append('rights', <any>element) as any || formParams;
            })
        }
        if (schools) {
            schools.forEach((element) => {
                formParams = formParams.append('schools', <any>element) as any || formParams;
            })
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/v1/Services/${encodeURIComponent(String(id))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
