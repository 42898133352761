<div class="row">
  <div class="col-lg-12 text-center">
    <img class="logo-login" src="assets/images/logo_vo.png">
  </div>
</div>

<p class="sub-title">Bonjour! Connectez-vous à l'aide de votre adresse mail.</p>

<nb-alert
  *ngIf="showMessages.error && errors?.length && !submitted"
  outline="danger"
  role="alert"
>
  <p class="alert-title"><b>Oh mince!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert
  *ngIf="showMessages.success && messages?.length && !submitted"
  outline="success"
  role="alert"
>
  <p class="alert-title"><b>Super!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">
      {{ message }}
    </li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-email">Email / Indentifiant :</label>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.email"
      #email="ngModel"
      name="email"
      id="input-email"
      placeholder="Email/Indentifiant"
      fieldSize="large"
      autofocus
      [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null"
    />
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Le champ est requis!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Mot de passe:</label>
      <a class="forgot-password caption-2" routerLink="../request-password"
        >Mot de passe oublié?</a
      >
    </span>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.password"
      #password="ngModel"
      name="password"
      type="password"
      id="input-password"
      placeholder="Password"
      fieldSize="large"
      [status]="
        password.dirty ? (password.invalid ? 'danger' : 'success') : 'basic'
      "
      [required]="getConfigValue('forms.validation.password.required')"
      [minlength]="getConfigValue('forms.validation.password.minLength')"
      [maxlength]="getConfigValue('forms.validation.password.maxLength')"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null"
    />
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Le champ est requis!
      </p>
      <p
        class="caption status-danger"
        *ngIf="password.errors?.minlength || password.errors?.maxlength"
      >
        Le mot de passe doit contenir entre
        {{ getConfigValue("forms.validation.password.minLength") }} et
        {{ getConfigValue("forms.validation.password.maxLength") }}
        caractères
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox
      name="rememberMe"
      [(ngModel)]="user.rememberMe"
      *ngIf="rememberMe"
      >Se souvenir de moi</nb-checkbox
    >
  </div>

  <button
    nbButton
    fullWidth
    status="primary"
    size="large"
    [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted"
  >
    Se connecter
  </button>
</form>
