/**
 * ENT API
 *  A RESTful API for ENT app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserEditDTO { 
    firstname: string;
    lastname: string;
    email: string;
    login: string;
    phone: string;
    pronoteid: string;
    password: string;
    credentialId: string;
    rights: Array<string>;
    active: boolean;
}