import { Injectable, OnDestroy } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { UserModel } from '../_store/model/user.model';
import { Store } from '@ngrx/store';
import { UserState } from '../_store/state/principal.state';
import { ClearUser, NewUser } from '../_store/actions/user.actions';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../_generated';
import { UserLogin } from '../_models/userLogin';

@Injectable()
export class InternUserService {
  user: any;
  store: Observable<UserModel>;

  constructor(
    private permissionService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private storeUser: Store<UserState>,
    private userService: UserService,
    private permissionsService: NgxPermissionsService,
  ) {
    this.store = storeUser.select('user');
  }

  initUser(): Promise<boolean> {
    return this.userService
      .getMeActionIndex()
      .toPromise()
      .then(user => {
        const userLogin = new UserLogin();
        userLogin.copy(user);
        userLogin.apiToken = this.cookieService.get('session-ent');
        this.setUser(userLogin);
        this.permissionsService.loadPermissions(userLogin.rights);
        return true;
      });
  }

  setUser(user) {
    this.storeUser.dispatch(new NewUser({ user: user }));

    // initialize the cookie with the api_token
    const sdate = new Date();
    sdate.setDate(sdate.getDate() + 1);
    if (!this.cookieService.check('session-ent')) this.cookieService.set('session-ent', user.apiToken, sdate);
  }

  removeUserToLocal() {
    // Clear cookies
    this.cookieService.deleteAll();
    this.cookieService.deleteAll('/auth', '/');
    this.cookieService.deleteAll('/', '/');
    this.cookieService.deleteAll('/pages', '/');

    this.cookieService.delete('session-ent');
    this.cookieService.delete('session-ent', '/');
    this.cookieService.delete('session-ent', '/pages');

    // Clear Users from localStorage
    this.storeUser.dispatch(new ClearUser());
  }
}
